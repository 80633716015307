.MemberContentList {
    .aino-link {
        background-color: $primary-color;
        height: 44px;
        padding: 10px 16px;
        color: #FFFFFF;
    }

    .ContentItem {
        box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        border-radius: 4px;
        padding-bottom: 10px;
        position: relative;

        .image {
            background: url("../../img/member_content.png") no-repeat top;
            background-size: cover;
            height: 170px;
        }

        .type {
            position: absolute;
            top: -11px;
            left: -7px;

            .member_video, .member_post, .member_pdf {
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: space-around;
                color: #FFFFFF;
                padding: 4px;
            }

            .member_video {
                background-color: #FFC107;
            }

            .member_post {
                background-color: #007BFF;
            }

            .member_pdf {
                background-color: #DC3545;
            }
        }

        .content {
            margin: 16px;

            .content-name {
                font-weight: bold;
            }

            .content-name, .brief-content {
                font-size: $font-size-16;
                color: #263957;
                max-height: 72px;
                height: 72px;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                display: -webkit-box;
            }

            .member_free, .member_paid {
                color: #FFFFFF;
                width: 64px;
                border-radius: 4px;
                height: 24px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .member_free {
                background-color: #0065FF;
            }

            .member_paid {
                background-color: #FF5630;
            }
        }
    }

    #wrapSearchBox {
        position: absolute;
        display: none;
        width: 400px;
        height: auto;
        background-color: #FFFFFF;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        border-radius: 8px;
        padding: 24px;
        z-index: 2;

        .title-tree {
            color: $primary-color;
            margin-top: 32px;
            margin-bottom: 24px;
        }

        .icon-calendar {
            height: 42px !important;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            background-color: white;
            border-left: none;
        }

        input#datepicker1,
        input#datepicker2 {
            border-right: none;
        }

        #ainoTreeSearchSeminar {
            padding-left: 0;
        }

        ul {
            list-style: none;

            li {
                margin-top: 10px;
            }
        }
    }

    @media (max-width: 768px) {
        .wrap-one-column {
            display: none;
        }

        #wrapSearchBox {
            width: 100% !important;
        }
    }

    @media (min-width: 768px) and (max-width: 1000px) {
        .content-name, .brief-content {
            height: 50px;
            -webkit-line-clamp: 2;
        }
    }
}
