.headerPage {
    width: 100%;
    background-color: #28A745;

    .logo-jeccs {
        background-image: url("../../resources/img/jeccs-logo.png");
        width: 34px;
        height: 38px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .custom-toggler.navbar-toggler {
        border: none !important;
        background-color: transparent !important;
        color: transparent !important;
        padding-right: 0;
    }

    .custom-toggler .navbar-toggler-icon {
        font-size: 20px;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='5' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
    }

    .text-logo {
        color: #ffffff;
        font-size: 20px;
        margin-left: 10px;
    }

    .text-logo:hover {
        color: #FFFFFF;
    }

    .nav-link {
        color: #ffffff !important;
    }
}
