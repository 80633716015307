// Fonts

// @font-face {
//     font-family: "yu-mincho-regular";
//     src: url("../../resources/fonts/yumin.ttf");
// }

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

body {
    font-family: '游明朝','Yu Mincho',YuMincho,'Hiragino Mincho Pro',serif;
    color: #1c1c1c;
}

// global
.a-link {
    color: $primary-color;
}

.field-required {
    color: #ff5630;
}

div {
    color: #5f6c83;
}


.pagination-web, .pagination-mobile {
    width: 100%;
}

@media (max-width: 992px) {
    .pagination-web {
        display: none !important;
    }

    .pagination-mobile {
        display: block !important;
    }
}

@media (min-width: 992px) {
    .pagination-web {
        display: block !important;
    }

    .pagination-mobile {
        display: none !important;
    }
}

#wrap-search-box {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 0.5;
    background-color: #5f6c83;
}


.w-pc-70 {
    width: 70%;
}

.w-pc-30 {
    width: 30%;
}

#listAddressCityStreet {
    height: 300px;
    overflow-y: scroll;
    padding-left: 5px;
}

.btn-load-api {
    border-color: #6c757d;
    height: 38px;
}

@media (min-width: 1000px) {
    .w-pc-70 {
        width: 80%;
    }

    .w-pc-30 {
        width: 20%;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .w-pc-70 {
        width: 60%;
    }

    .w-pc-30 {
        width: 40%;
    }
}

@media (max-width: 768px) {
    .w-pc-70 {
        width: 50%;
    }

    .w-pc-30 {
        width: 50%;
    }
}

// applications
@import "login";
@import "header";
@import "header-page";
@import "password_email";
@import "password_reset";
@import "dashboard";
@import "user_notice";
@import "register/member_register";
@import "loading";
@import "menu-left/menu-left";
@import "seminars/list";
@import "seminars/item";
@import "seminars/register-seminar";
@import "seminars/medical";
@import "donate/style";
@import "payment/create";
@import "payment/status";
@import "payment/list";
@import "contents/list";
@import "contents/detail";
@import "tests/list";
@import "user-seminar/list";
@import "user-seminar/result";

@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/brands.scss";
@import "~@fortawesome/fontawesome-free/scss/solid.scss";
