.registerSeminar {
    color: #7a8699;

    @media (max-width: 768px) {
        .wrap-one-column {
            display: none;
        }
    }

    .seminar-title {
        font-size: $font-size-16;
        color: #263957;
        margin-bottom: 32px;
    }

    .text-color {
        color: $primary-color;
        margin-bottom: 10px;
    }

    .link-detail {
        color: #0065ff;
        margin-top: 24px;
        font-size: $font-size-15;
    }

    .wrap-link {
        margin-top: 24px;
        margin-bottom: 10px;
    }

    .warning {
        color: #ff5630;
        font-size: $font-size-16;
    }

    .aino-next {
        background-color: $primary-color;
        color: #ffffff;
        height: 44px;
        width: 100%;
        border: 1px solid #d0caca;
        margin-top: 42px;
    }

    .go-to-login {
        background-color: $primary-color;
        color: #ffffff;
        height: 44px;
        border: 1px solid #d0caca;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .go-to-register {
        background-color: #2d6efd;
        color: #ffffff;
        height: 44px;
        border: 1px solid #d0caca;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .aino-back {
        background-color: #ffffff;
        color: #7a8699;
        height: 44px;
        width: 100%;
        border: 1px solid #d0caca;
        margin-top: 24px;
    }

    .text-register-success {
        color: #263957;
        font-size: $font-size-16;
    }

    .link-top-page {
        background-color: $primary-color;
        color: #ffffff;
        height: 44px;
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media (max-width: 768px) {
        .wrap-login, .wrap-register {
            width: 100%;
        }
    }
}
