.PaymentList {
    .item {
        box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        border-radius: 4px;
        padding: 16px;
        min-height: 300px;
        height: 320px;

        .aino-link {
            background-color: $primary-color;
            height: 44px;
            padding: 10px 16px;
            color: #FFFFFF;
            width: 100%;
        }

        .seminar-name {
            font-weight: 600;
        }
    }

    .itemOld {
        box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        border-radius: 4px;
        padding: 16px;
        min-height: 220px;

        .aino-link {
            background-color: $primary-color;
            height: 44px;
            padding: 10px 16px;
            color: #FFFFFF;
            width: 100%;
        }

        .seminar-name {
            // -webkit-line-clamp: 1;
            // -webkit-box-orient: vertical;
            // overflow: hidden;
            // display: -webkit-box;
            font-weight: 600;
            text-decoration: underline;
        }
    }
}
