.MemberContentDetail {
    .wrap-content {
        .wrap-header {
            display: flex;
            align-items: center;
            gap: 15px;

            .member_free, .member_paid {
                color: #FFFFFF;
                width: 64px;
                border-radius: 4px;
                height: 24px;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }

            .member_free {
                background-color: #0065FF;
            }

            .member_paid {
                background-color: #FF5630;
            }
        }

        .btn-back {
            background-color: #ffffff;
            font-size: 20px;
            color: #7a8699;
            width: 100%;
            height: 44px;
            margin-top: 24px;
            border: 1px solid #b3bac5;
        }
    }
}
