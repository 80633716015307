.UserNotice {
    margin-bottom: 20px;

    .date {
        color: $primary-color;
        font-size: $font-size-18;
    }

    .title {
        color: #000000;
        font-size: $font-size-18;
        text-decoration: underline;
        margin-top: 8px;
        margin-bottom: 26px;
    }

    .description {
        min-height: 110px;
    }

    .line {
        border-bottom: 1px solid #DFE1E6;
    }

    .aino-link {
        color: $primary-color;
        text-decoration: underline;
    }
}

.notice-card {
    @media (min-width: 768px) {
        .card-body {
            height: 180px;
            overflow: hidden;
        }
    }
    img {
        // display: block;
        float: left;
        height: 80px;
        width: 60px;
        margin: 0 1rem 100px 0;
        object-fit: cover;
    }
}


