.PasswordReset {
    .header {
        background-color: #E2E6EA;
        height: 62px;

        .text-1 {
            font-size: $font-size-12;
            margin-top: 5px;
            margin-bottom: 7px;
        }

        .text-2 {
            font-size: $font-size-20;
            font-weight: bold;
        }
    }

    .form-header-text {
        margin-top: 36px;
        margin-bottom: 22px;
    }

    .btn-submit {
        background-color: $primary-color;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 24px;
    }

    .text-notice-success {
        margin-top: 24px;
        margin-bottom: 32px;
        font-size: $font-size-15;
    }

    .aino-link {
        background-color: #28A745;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        text-decoration: none;
        border-radius: 4px;
        padding: 10px 25px;
        text-align: center;
    }
}
