.HeaderPage {
    background-color: #E2E6EA;
    height: 64px;

    .wrap-text {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        color: #263957;

        .header-text-1 {
            font-size: $font-size-12;
            margin-top: 5px;
            margin-bottom: 2px;
            font-weight: 600;
        }

        .header-text-2 {
            font-size: $font-size-20;
            font-weight: 600;
            padding-bottom: 8px;
        }
    }
}
