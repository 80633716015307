.aino-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #333333;
    opacity: 0.5;
    width: 100%;
    text-align: center;
    display: none;
    z-index: 100;

    .loader {
        position: absolute;
        top: 50%;
        color: white;
    }
}

