.seminarItem {
    height: 360px;
    background: #FFFFFF;
    box-shadow: 0 4px 8px rgba(1, 18, 34, 0.1);
    border-radius: 4px;
    padding: 16px;

    .seminar-class-id {
        display: flex;
        width: 100%;
        margin-bottom: 10px;

        .label-class-id-1 {
            background-color: #FFAB00;
            margin-right: 8px;
            border-radius: 4px;
            color: #FFFFFF;
            padding: 4px 8px;
            font-size: $font-size-12;
        }

        .label-class-id-2 {
            border-radius: 4px;
            background-color: #0065FF;
            color: #FFFFFF;
            padding: 4px 8px;
            font-size: $font-size-12;
        }
    }

    .seminar-start-date {
        margin-bottom: 10px;
        font-size: $font-size-18;
        color: $primary-color;
    }

    .seminar-register-end-date {
        margin-bottom: 10px;
        font-size: $font-size-15;
        color: #FF5630;
    }

    .seminar-name {
        font-size: $font-size-16;
        text-decoration: underline;
        color: #263957;
        max-height: 72px;
        height: 72px;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
    }

    .seminar-btn-group {
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-top: 15px;
    }

    .aino-link {
        background-color: $primary-color;
        height: 44px;
        width: 180px;
        margin-top: 10px;
    }

    .aino-link-white {
        background-color: #FFFFFF;
        height: 44px;
        width: 180px;
        border: 1px solid #d0caca;
    }

    .expired {
        color: #FF5630;
        font-size: $font-size-16;
        margin-top: 12px;
    }
}

@media (min-width: 768px) and (max-width: 1000px) {
    .seminarItem {
        .seminar-name {
            height: 50px;
            -webkit-line-clamp: 2;
        }
    }
}
