#methodPay {
    .wrapper {
        width: 100%;
        padding-left: 114px;
        padding-right: 114px;
        padding-top: 24px;
        padding-bottom: 30px;

        #contentBank, #contentCard {
            margin-top: 20px;

            .header {
                border-bottom: 1px solid #7A8699;
                padding-bottom: 5px;
                margin-bottom: 16px;

                .text {
                    font-size: $font-size-20;
                    font-weight: 600;
                    color: #333333;
                }
            }
        }

        .product_price {
            font-size: $font-size-20;
            font-weight: 600;
        }

        .product_title {
            color: $primary-color;
        }

        .wrap-content {
            width: 500px;

            .active {
                color: $primary-color;
            }

            .wrap-method {
                display: flex;
                align-items: center;

                #methodCard, #methodBank {
                    width: 50%;
                    border: 1px solid #ebecf1;
                    text-align: center;
                    padding: 4px;
                    cursor: pointer;
                }

                #methodCard {
                    border-right: none;
                }
            }

            .btn-submit {
                background-color: $primary-color;
                font-size: 20px;
                color: #ffffff;
                width: 100%;
                height: 48px;
                margin-top: 24px;
            }

            .btn-back {
                background-color: #ffffff;
                font-size: 20px;
                color: #7a8699;
                width: 100%;
                height: 48px;
                margin-top: 24px;
                border: 1px solid #b3bac5;
            }

            #contentCard {
                .listCards {
                    background-image: url("../../../resources/img/list_cards.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: contain;
                    height: 40px;
                    width: 300px;
                }

                #cardNumberUI, #cardExpiredUI, #cardCvcUI {
                    height: 38px;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    border-radius: 4px;
                    border: 1px solid #ced4da;
                }
            }

            #contentBank {
                .title {
                    color: $primary-color;
                }

                .wrap-info-payment {
                    padding: 16px;
                    margin-top: 32px;
                    box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
                    border-radius: 4px;
                }
                .info-bank {
                    font-size: $font-size-20;
                    font-weight: 600;
                }
            }
        }
    }

    @media (max-width: 768px) {
        .wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .wrap-content {
                width: 100%;
            }

            .wrap-info-payment {
                font-size: $font-size-16 !important;
            }
        }
    }

    @media (min-width: 768px) and (max-width: 1100px) {
        .wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .wrap-content {
                width: 100%;
            }
        }
    }
}

