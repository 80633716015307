.Tests {

    .aino-next {
        background-color: $primary-color;
        color: #ffffff;
        height: 44px;
        width: 100%;
        border: 1px solid #d0caca;
        margin-top: 42px;
    }

    .btn-retest {
        background-color: #28a745;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 25px;
        text-decoration: none;
        border-radius: 4px;
        padding: 10px 20px;
        text-align: center;
    }

    .btn-back-list {
        background-color: #ffffff;
        font-size: 20px;
        color: #7a8699;
        width: 100%;
        height: 48px;
        margin-top: 24px;
        border: 1px solid #b3bac5;
    }

    .ItemTest {
        box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        background-color: #FFFFFF;
        padding: 16px;
        min-height: 156px;

        .test-name {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            color: #263957;
            font-size: $font-size-16;
        }

        .date {
            color: #263957;
            font-size: $font-size-12;
            display: flex;
            align-items: center;
            gap: 10px;

            .tested, .not-test, .testing {
                color: #FFFFFF;
                padding: 4px 8px;
                border-radius: 4px;
            }

            .tested {
                background-color: #0065FF;
            }

            .not-test {
                background-color: #6554C0;
            }

            .testing {
                background-color: #ff8a00;
            }
        }

        .aino-link {
            background-color: $primary-color;
            height: 44px;
            width: 100%;
            margin-top: 30px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ItemTestResult {
        .test-name {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
            color: #263957;
            font-size: $font-size-16;
        }

        .date {
            color: #263957;
            font-size: $font-size-15;
        }

        .not-pass, .pass {
            color: #FFFFFF;
            padding: 4px 8px;
            border-radius: 4px;
        }

        .not-pass {
            background-color: #FF5630;
        }

        .pass {
            background-color: #28A745;
        }

        .status, .date, .result {
            font-size: $font-size-15;
        }

        .line {
            border: 1px solid #DFE1E6;
            margin-top: 24px;
        }

        .question_description {
            padding: 16px;
            background-color: #E3FCEF;
            border-radius: 4px;
            border: 1px solid #36B37E;
            box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        }

        .correct, .incorrect {
            padding: 4px 8px;
            border-radius: 4px;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
        }

        .correct {
            background-color: #28A745;
            width: 40px;
            height: 24px;
        }

        .incorrect {
            background-color: #FF5630;
            width: 52px;
            height: 24px;
        }
    }

    @media (max-width: 768px) {
        .wrap-one-column {
            display: none;
        }
    }

    @media (max-width: 1700px) {
        .ItemTest {
            .date {
                display: block !important;

                .tested, .not-test, .testing {
                    width: 60px;
                    text-align: center;
                }
            }
        }
    }
}
