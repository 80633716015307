.Dashboard {
    .wrap-1 {
        margin-top: 20px;
        margin-bottom: 16px;
    }

    .wrap-2 {
        margin-bottom: 24px;
    }

    .title-list {
        font-size: $font-size-20;
        font-weight: bold;
    }
}
