.PasswordEmail {
    .header {
        background-color: #e2e6ea;
        height: 62px;

        .text-1 {
            font-size: $font-size-12;
            margin-top: 5px;
            margin-bottom: 7px;
        }

        .text-2 {
            font-size: $font-size-20;
            font-weight: bold;
        }
    }

    .form-header-text {
        margin-top: 36px;
        margin-bottom: 22px;
        font-size: $font-size-15;
    }

    .btn-submit {
        background-color: $primary-color;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 24px;
    }

    .msg-error {
        width: 100%;
        margin-top: -10px;
        font-size: 0.875em;
        color: $error-color;
    }

    .content-bottom {
        margin-top: 24px;
        font-size: 15px;
    }

    .aino-link {
        background-color: #28a745;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 10px;
        text-decoration: none;
        border-radius: 4px;
        padding: 10px 20px;
        text-align: center;
    }
}
