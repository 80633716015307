.listSeminars {
    color: #7A8699;

    .aino-link {
        background-color: $primary-color;
        height: 44px;
        padding: 10px 16px;
        color: #FFFFFF;
    }

    #wrapSearchBox {
        position: absolute;
        display: none;
        width: 400px;
        height: auto;
        background-color: #FFFFFF;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        box-shadow: 0px 4px 8px rgba(1, 18, 34, 0.1);
        border-radius: 8px;
        padding: 24px;
        z-index: 2;

        .title-tree {
            color: $primary-color;
            margin-top: 32px;
            margin-bottom: 24px;
        }

        .icon-calendar {
            height: 42px !important;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            background-color: white;
            border-left: none;
        }

        input#datepicker1,
        input#datepicker2 {
            border-right: none;
        }

        #ainoTreeSearchSeminar {
            padding-left: 0;
        }

        ul {
            list-style: none;

            li {
                margin-top: 10px;
            }
        }
    }

    @media (max-width: 768px) {
        .wrap-one-column {
            display: none;
        }

        #wrapSearchBox {
            width: 100% !important;
        }
    }
}
