.seminarRegisterMedical {
    margin-bottom: 50px;

    .wrapper {
        width: 100%;
        padding-left: 114px;
        padding-right: 114px;
        padding-top: 24px;

        .wrap-date-range {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 10px;
            width: 400px;

            .icon-calendar {
                height: 42px;
                border-top-left-radius: 0px;
                border-bottom-left-radius: 0px;
                background-color: white;
                border-left: none;
            }
        }

        .agree-wrap {
            background-color: #e3fcef;
            padding: 16px;
            margin-top: 32px;
            margin-bottom: 24px;
            font-size: 18px;
            border: 1px solid #36b37e;
            border-radius: 4px;
        }

        .btn-submit {
            background-color: $primary-color;
            font-size: 20px;
            color: #ffffff;
            width: 100%;
            height: 48px;
            margin-top: 24px;
        }

        .btn-back {
            background-color: #ffffff;
            font-size: 20px;
            color: #7a8699;
            width: 100%;
            height: 48px;
            margin-top: 24px;
            border: 1px solid #b3bac5;
        }
    }

    @media (max-width: 768px) {
        .wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;

            .wrap-date-range {
                width: 100%;
                display: block !important;

                .wrap-space-datepicker {
                    margin-top: 15px;

                    .space-datepicker {
                        display: none;
                    }
                }
            }
        }
    }
}
