.UserSeminarHistory {

    .UserSeminarItem {
        height: 320px;
        background: #FFFFFF;
        box-shadow: 0 4px 8px rgba(1, 18, 34, 0.1);
        border-radius: 4px;
        padding: 16px;

        .seminar_name {
            font-weight: bold;
            // -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            // overflow: hidden;
            display: -webkit-box;
        }

        .button_user_seminar {
            position: absolute;
            width: 100%;
            margin-top: 20px;
            bottom: 0;

            .aino-link {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $primary-color;
                height: 44px;
                width: 100%;
                color: #FFFFFF;
            }
        }
    }

    .UserSeminarItemOld {
        border-bottom: 1px solid #DFE1E6;
        padding-bottom: 15px;

        .seminar_name {
            font-weight: bold;
            text-decoration: underline;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            display: -webkit-box;
        }
    }

    @media (max-width: 1300px) {
        .UserSeminarItem {
            height: 290px;
        }
    }
}
