.button_result{
    .button_user_seminar {
        /* position: absolute; */
        width: 40%;
        margin-top: 20px;
        margin-bottom: 20px;
    
        .aino-link {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $primary-color;
            height: 44px;
            width: 100%;
            color: #FFFFFF;
        }
    }
    @media (max-width: 1300px) {
        .UserSeminarItem {
            height: 290px;
        }
    }
}