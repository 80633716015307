.pd-15 {
    padding: 15px;
}

.pt-15 {
    padding-top: 15px;
}

.pt-20 {
    padding-top: 20px;
}

.pb-15 {
    padding-bottom: 15px;
}

.pb-20 {
    padding-bottom: 20px;
}

.mb-30 {
    margin-bottom: 30px;
}

.mb-32 {
    margin-bottom: 32px;
}

.mb-15 {
    margin-bottom: 15px;
}

.mb-50 {
    margin-bottom: 50px;
}

.pl-24 {
    padding-left: 24px;
}

.container-aino {
    width: 100%;
    padding-left: 24px;
    padding-right: 24px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    flex-wrap: inherit;
}

label.aino-error-form, div.aino-error-form, span.aino-error-form {
    color: #dc3545;
    font-size: 16px;
}

.error-system {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.875em;
    color: #dc3545;
}

.wrap-content-right {
    padding-right: 24px;
    padding-left: 24px;
    width: 100%;
}

.no-pdl-0 {
    padding-left: 0;
}

.no-pdr-0 {
    padding-right: 0;
}

.no-mr-0 {
    margin-right: 0;
}

.no-ml-0 {
    margin-left: 0;
}

.invalid-feedback {
    display: block !important;
}

@media (min-width: 768px) {
    .container-aino {
        margin: auto;
        justify-content: center;
    }
}

.pb-24 {
    padding-bottom: 24px;
}

.mt-36 {
    margin-top: 36px;
}

.mt-24 {
    margin-top: 24px;
}

.mt-16 {
    margin-top: 16px;
}

.mt-30 {
    margin-top: 30px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-8 {
    margin-top: 8px;
}

.custom-modal {
    opacity: 0 !important;
}

.col-form-label {
    font-weight: 600;
}

.form-control::placeholder {
    color: #e2e6ea;
}

.aino-back {
    background-color: #ffffff;
    color: #7a8699;
    height: 44px;
    width: 100%;
    border: 1px solid #d0caca;
    margin-top: 24px;
}

.aino-back:hover {
    border: 1px solid #d0caca;
}

.fs-15 {
    font-size: 15px;
}

.fs-16 {
    font-size: 16px;
}

.color-primary {
    color: #28A745;
}


.wrap-user__info {
    margin-bottom: 16px;
}

.wrap-user__info .wrap-user__info--text {
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: 600;
}

.wrap-user__info .wrap-user__info--value {
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.btn-change__info {
    font-family: 'Yu Mincho';
}

.preview-form input {
    pointer-events: none;
    border: none;
    padding: 0
}

.preview-form input::placeholder {
    color: transparent;
}

.preview-form select {
    pointer-events: none;
    border: none;
    background-image: none;
    padding: 0
}

.preview-form textarea {
    pointer-events: none;
    border: none;
    padding: 0
}

.preview-form .input-group-append {
    display: none;
}

.preview-form .btn-load-api {
    display: none;
}

.preview-form .field-required {
    display: none;
}


.preview-form .form-control:disabled, .form-control[readonly] {
    background: none;
}

.preview-form-profile .col-form-label {
    font-size: 16px;
    line-height: 24px;
    flex: none;
    order: 0;
    flex-grow: 0;
    font-weight: bold;
}

.preview-form .form-check-input:checked[type=radio] {
    background: none;
}

.preview-form .form-check {
    padding: 0;
}


.preview-form-profile .field-title {
    color: #28A745;
    font-size: 18px;
}
