.loginPage {
    color: $color-text;
    margin-bottom: 30px;

    .bg-login {
        position: relative;
    }

    .bg-pc {
        width: 100%;
        display: none;
    }

    .login-info-text {
        position: absolute;
        bottom: 0;
        padding-bottom: 0;

        .text-1 {
            font-size: $font-size-12;
            font-weight: bold;
        }

        .text-2 {
            font-size: $font-size-20;
            font-weight: bold;
        }
    }

    .login-info {
        position: absolute;
        bottom: 0;
        padding-bottom: 0;
        background-color: #E2E6EA;
        width: 100%;
        opacity: 0.5;
        height: 62px;
    }

    .text-3 {
        font-size: $font-size-15;
    }

    .text-4 {
        font-size: $font-size-15;
    }

    .text-5 {
        font-size: $font-size-15;
    }

    .loginForm {
        .label-email {
            font-size: $font-size-15;
        }

        .label-password {
            font-size: $font-size-15;
        }

        .msg-error {
            width: 100%;
            margin-top: -10px;
            font-size: 0.875em;
            color: $error-color;
        }
    }

    .aino-link {
        color: $primary-color;
        text-decoration: underline;
    }

    .btn-submit {
        background-color: $primary-color;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
    }

    @media (max-width: 768px) {
        .bg-login {
            background: url("../img/bg-login.jpg") no-repeat center;
            background-size: cover;
            height: 237px;
        }
    }

    @media (min-width: 768px) {
        .bg-pc {
            display: block;
        }
    }
}
