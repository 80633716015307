// Body
$primary-color: #28A745;
$error-color: #dc3545;
$color-text: #263957;

// Typography
$font-family-sans-serif: 'yu-mincho-regular', sans-serif;

$font-size-12: 12px;
$font-size-15: 15px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
