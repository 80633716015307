.MemberRegister {
    padding-bottom: 50px;

    .wrapper {
        width: 100%;
        padding-left: 114px;
        padding-right: 114px;
        padding-top: 24px;

        .register-success {
            display: none;
            line-height: 30px;
        }

        #text-header-form {
            font-size: $font-size-16;
            margin-bottom: 32px;

            .text-header-red {
                color: #ff5630;
            }
        }

        .icon-calendar {
            height: 42px !important;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
            background-color: white;
            border-left: none;
        }

        .wrap-preview-text {
            word-break: break-word;
        }

        #listField {
            margin-top: 32px;
        }

        .btn-load-api {
            border-color: #6c757d;
            height: 38px;
        }

        .form-register {
            margin-top: 32px;

            .field-title {
                color: $primary-color;
                font-size: $font-size-18;
            }

            textarea {
                resize: none;
            }
        }
    }

    #member_type_list {
        .member-type-item {
            margin-bottom: 16px;
        }
    }

    .agree-wrap {
        background-color: #e3fcef;
        padding: 16px;
        margin-top: 32px;
        margin-bottom: 24px;
        font-size: 18px;
        border: 1px solid #36b37e;
        border-radius: 4px;
    }

    .btn-submit {
        background-color: $primary-color;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 24px;
    }

    .btn-back {
        background-color: #ffffff;
        font-size: 20px;
        color: #7a8699;
        width: 100%;
        height: 48px;
        margin-top: 24px;
        border: 1px solid #b3bac5;
    }

    .aino-link {
        background-color: #28a745;
        font-size: 20px;
        color: #ffffff;
        width: 100%;
        height: 48px;
        margin-top: 25px;
        text-decoration: none;
        border-radius: 4px;
        padding: 10px 20px;
        text-align: center;
    }

    @media (max-width: 768px) {
        .wrapper {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}
