.paymentStatus {
    .link-top-page {
        background-color: $primary-color;
        color: #ffffff;
        height: 44px;
        width: 100%;
        margin-top: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-message {
        font-size: $font-size-16;
    }
}
