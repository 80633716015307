.wrap-menu-left {
    background-color: #f4f5f7;
    min-height: calc(100vh - 118px);

    .list-menu-left {
        list-style: none;
        padding-left: 24px;
        padding-right: 24px;
        width: 260px;

        .list-menu-left-item {
            height: 24px;
            margin-top: 24px;

            svg {
                color: #a9a9a9;
            }
        }

        .list-menu-left-item-title {
            border-bottom: 1px solid #dfe1e6;
            font-weight: bold;
        }

        .list-menu-left-item-auth {
            border-bottom: 1px solid #dfe1e6;
            margin-top: 10px;
        }
    }

    .active-link {
        color: $primary-color;
    }

    a {
        color: #263957;
        text-decoration: none;
        font-size: $font-size-15;
    }
}

.list-menu-left-mobile {
    display: none;
    list-style: none;
}

@media (max-width: 768px) {
    .wrap-menu-left {
        display: none;
    }

    #wrapSidenav {
        width: 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
    }

    #bg-black {
        width: calc(100% - 300px);
        height: 100%;
        background-color: #000000;
        opacity: 0.8;
    }

    .sidenav {
        height: 100%;
        width: 0;
        position: fixed;
        z-index: 1;
        top: 0;
        right: 0;
        background-color: #f4f5f7;
        transition: 0.2s;
        padding-left: 0;

        .list-menu-left-mobile {
            display: block;
            padding-left: 24px;
            padding-right: 24px;

            .list-menu-left-item {
                height: 24px;
                margin-top: 24px;

                svg {
                    color: #a9a9a9;
                }
            }

            .list-menu-left-item-title {
                border-bottom: 1px solid #dfe1e6;
                font-weight: bold;
            }

            .list-menu-left-item-auth {
                border-bottom: 1px solid #dfe1e6;
                margin-top: 10px;
            }
        }

        a {
            color: #263957;
            text-decoration: none;
            font-size: $font-size-16;
        }
    }
}
